export const GET_CLIENTS = gql`
  query AllClients($first: Int!, $page: Int!, $query: SearchLike!) {
    AllClients(first: $first, page: $page, name: $query) {
      paginatorInfo {
        count
        currentPage
        total
        lastPage
      }
      data {
        id
        name
        Token {
          id
        }
        ActiveToken {
          id
        }
      }
    }
  }
`;

export const GET_CLIENT_IDS = gql`
  query AllClientIDs {
    AllClientIDs {
      name
      id
    }
  }
`;

export const GET_USERS_REQUESTS = gql`
  query MyQuery(
    $status: String = "OPEN"
    $first: Int
    $page: Int
    $query: SearchLike!
  ) {
    SingleClientRequests(
      status: $status
      first: $first
      page: $page
      event_title: $query
    ) {
      paginatorInfo {
        count
        currentPage
        total
        lastPage
      }
      data {
        created_at
        id
        custom_event
        ticket_type
        ticket_count
        status
        offers {
          status
        }
      }
    }
  }
`;

export const GET_USERS_REQUESTS_CANCELED = gql`
  query MyQuery(
    $status: String = "ADMIN_CANCELED"
    $first: Int
    $page: Int
    $query: SearchLike!
  ) {
    SingleClientRequests(
      status: $status
      first: $first
      page: $page
      event_title: $query
    ) {
      paginatorInfo {
        count
        currentPage
        total
        lastPage
      }
      data {
        created_at
        id
        custom_event
        ticket_type
        ticket_count
        status
        status_date
        offers {
          status
        }
      }
    }
  }
`;

export const GET_USERS_OFFERS = gql`
  query MyQuery(
    $status: String = "OFFERED"
    $first: Int
    $page: Int
    $query: SearchLike!
  ) {
    SingleClientRequests(
      status: $status
      first: $first
      page: $page
      event_title: $query
    ) {
      paginatorInfo {
        count
        currentPage
        total
        lastPage
      }
      data {
        id
        custom_event
        status
        ticket_type
        ticket_count
        created_at
        offers {
          status
        }
      }
    }
  }
`;

export const GET_OFFERED_OFFERS = gql`
  query MyQuery {
    clientOpenOffers(first: 10) {
      data {
        id
        custom_event
        status
        ticket_type
        ticket_count
        reference
        created_at
        offers {
          status
        }
      }
    }
  }
`;

export const GET_DECLINED_OFFERS = gql`
  query MyQuery {
    clientDeclinedOffers(first: 10) {
      data {
        id
        custom_event
        status
        ticket_type
        ticket_count
        reference
        created_at
        offers {
          status
          status_date
        }
      }
    }
  }
`;

export const GET_OFFERS_ADMIN = gql`
  query ClientBookingsWithoutOrder(
    $first: Int
    $page: Int
    $query: SearchLike!
  ) {
    SingleAdminRequests(first: $first, page: $page, event_title: $query) {
      paginatorInfo {
        count
        currentPage
        total
        lastPage
      }
      data {
        id
        custom_event
        status
        SendOffers {
          id
        }
        user {
          firstname
          name
          email
        }
        client {
          name
        }
      }
    }
  }
`;

export const GET_USERS_ORDERS = gql`
  query MyQuery($first: Int!, $page: Int!) {
    SingleClientOrders(
      status: ["ORDERED", "BILL_SEND", "PAID"]
      first: $first
      page: $page
    ) {
      paginatorInfo {
        count
        currentPage
        total
        lastPage
      }
      data {
        id
        custom_event
        status
        Bill {
          status
        }
        offers {
          status
        }
      }
    }
  }
`;

export const GET_USERS_BILLS = gql`
  query MyQuery {
    getUserBills {
      id
      status
      Order {
        id
        custom_event
      }
    }
  }
`;

export const GET_USERS_STATS = gql`
  query MyQuery {
    OrderStats {
      id
      status
    }
  }
`;
